import * as React from "react"
import { styled } from "../stitches.config"

const Input = styled("input", {
    all: "unset",
    boxSizing: "border-box",
    border: "solid 1px $mauve11",
    px: "$2",
    py: "$2",
    borderRadius: "$1"
})

export default Input;