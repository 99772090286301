import { graphql } from "gatsby"
import * as React from "react"
import A from "../components/A"
import Searchbar from "../components/Searchbar"
import Section from "../components/Section"
import SEO from "../components/SEO"
import Span from "../components/Span"
import Text from "../components/Text"
import init, { search } from "tinysearch-engine"
import { IPost } from "../types/post"

interface NotesPageProps {
    data: {
        posts: { edges: { node: IPost }[] }
    }
}

export default function NotesPage({
    data: {
        posts: { edges },
    },
}: NotesPageProps) {
    const [searchResults, setSearchResults] = React.useState([])

    React.useEffect(() => {
        async function run() {
            await init()
        }
        run()
    }, [])

    const handleChange = (value: string) => {
        setSearchResults(search(value, 5))
    }

    return (
        <>
            <SEO title="️Notes ✍️" />
            <Section>
                <Text size="heading-03">
                    You've stubled 👀 upon my notes, which I write to think.
                    They are written for myself and they contain my opinions and
                    principles. If anything here triggers interesting thoughts,
                    send me an <A href="mailto:hello@robhar.com">email</A> and
                    let's talk!
                    <br />
                    <br />
                    Use the dark magic 🔮 powered (
                    <Span css={{ fontWeight: "bold", fontStyle: "italic" }}>
                        WASM
                    </Span>
                    ) search below to find some notes.
                </Text>
            </Section>
            <Section>
                <Text size="label-02" css={{ color: "$mauve9", mb: "$2" }}>
                    Search on whole words (e.g. rust, wasm)
                </Text>
                <Searchbar
                    onChange={handleChange}
                    onClose={() => setSearchResults([])}
                    items={searchResults}
                />
            </Section>
        </>
    )
}

export const pageQuery = graphql`
    query {
        posts: allMdx(
            filter: { frontmatter: { type: { regex: "/post|note/" } } }
            sort: { order: DESC, fields: frontmatter___date }
            limit: 10
        ) {
            edges {
                node {
                    id
                    excerpt(truncate: true, pruneLength: 140)
                    frontmatter {
                        author
                        date(fromNow: true)
                        tags
                        title
                    }
                    timeToRead
                    headings(depth: h1) {
                        value
                        depth
                    }
                    slug
                }
            }
        }
    }
`
