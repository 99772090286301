import * as React from "react"
import useEventListener from "@use-it/event-listener"
import { styled } from "../stitches.config"
import { motion } from "framer-motion";
import Box from "./Box"
import Input from "./Input"
import Text from "./Text"
import Link from "./Link"
import * as R from "ramda"

const StyledSearchbar = styled(Box, {
    width: "$full",
    position: "relative",
    mb: "$4",
    height: 40,
})

const InnerSearchbar = styled(motion(Box), {
    borderRadius: "$1",
    background: "$mauve5",
    position: "absolute",
    width: "$full",
    border: "solid 1px $mauve6",
    transition: "height 0.5s ease-in-out"
})

const StyledInput = styled(Input, {
    width: "$full",
    border: "none",
    color: "$mauve12",
    px: "$2",
    py: "$2",
})

const StyledResultsContainer = styled(motion(Box), {
    display: "flex",
    flexDirection: "column",
})

const Splitter = styled(Box, {
    width: "97%",
    mx: "auto",
    height: 1,
    background: "$mauve8",
})

const StyledResult = styled(Link, {
    color: "$mauve12",
    border: "none",
    p: "$2",
    "&:last-child": {
        borderBottomLeftRadius: "$1",
        borderBottomRightRadius: "$1",
    },
    variants: {
        active: {
            true: {
                background: "$realBlue",
                color: "$white",
            },
        },
    },
})


export interface SearchbarProps {
    onChange?: (value: string) => void
    onClose?: () => void
    items?: [string, string][] // [title, url][]
}

export default function Searchbar({
    items = [],
    onChange,
    onClose,
}: SearchbarProps) {
    const searchbarRef = React.useRef<HTMLDivElement>(null)

    // Selection index, the index of the selected item starting at 1 (0 is for no-selection)
    const [selectionIndex, setSelectionIndex] = React.useState(0)

    // When the user types, update the parent component
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (onChange) {
            onChange(event.target.value)
        }
    }

    const hasResults = () => items.length > 0

    // Handle all keydown events
    const keyDownHandler = (event: KeyboardEvent) => {
        const ctrlP = () => event.ctrlKey && event.key === "p"
        const ctrlJ = () => event.ctrlKey && event.key === "j"
        const ctrlK = () => event.ctrlKey && event.key === "k"
        const ctrlN = () => event.ctrlKey && event.key === "n"
        const arrowDown = () => event.key === "ArrowDown"
        const arrowUp = () => event.key === "ArrowUp"
        const esc = () => event.key === "Escape"
        const enter = () => event.key === "Enter"

        const isEsc = R.allPass([hasResults, esc])
        const isEnter = R.allPass([hasResults, enter])
        const isUp = () => hasResults() && (ctrlP() || ctrlK() || arrowUp())
        const isDown = () => hasResults() && (ctrlN() || ctrlJ() || arrowDown())

        if (isUp() && selectionIndex > 0) {
            event.preventDefault()
            setSelectionIndex(selectionIndex - 1)
        } else if (isDown() && selectionIndex < items.length) {
            event.preventDefault()
            setSelectionIndex(selectionIndex + 1)
        } else if (isEsc() && onClose)  {
            event.preventDefault()
            onClose()
        } else if (isEnter() && selectionIndex > 0) {
            const [_title, url] = items[selectionIndex -1] ?? null
            if (typeof window !== 'undefined') {
                window.location.href = url;
           }
        }
    }

    // Detect if the user clicked outside of the search-bar
    const outsideClickHandler = (event: MouseEvent) => {
        if (
            searchbarRef.current &&
            event.target &&
            !searchbarRef.current.contains(event.target as unknown as Node) &&
            hasResults() &&
            onClose
        ) {
            onClose()
        }
    }

    // Bind listeners, these are cleaned up nicely via 'useEventListener'
    useEventListener("keydown", keyDownHandler)
    useEventListener("click", outsideClickHandler)

    return (
        <StyledSearchbar ref={searchbarRef}>
            <InnerSearchbar>
                <StyledInput
                    placeholder="Search"
                    type="text"
                    onChange={handleChange}
                />
                {items.length > 0 && (
                    <>
                        <Splitter />
                        <StyledResultsContainer layout>
                            {items.map(([title, url], i) => (
                                <StyledResult
                                    active={i + 1 === selectionIndex}
                                    to={url}
                                    key={`${i}-${title}`}
                                >
                                    <Text size="heading-01">{title}</Text>
                                </StyledResult>
                            ))}
                        </StyledResultsContainer>
                    </>
                )}
            </InnerSearchbar>
        </StyledSearchbar>
    )
}
